import React from 'react'
import Layout from '../layouts'

const NotFound = () => (
  <Layout>
    <p>404 page</p>
  </Layout>
)

export default NotFound
